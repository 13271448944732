import { ApiResponse } from "@models/api-response";

export async function deleteIncident(id: string): Promise<ApiResponse<null>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/incidents/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    return [null, true];
  } catch (error) {
    return [null, false];
  }
}