export const INCIDENT_SEVERITIES = [
  {
    id: "info",
    name: "Info",
    color: "#77a1ec",
  },
  {
    id: "warn",
    name: "Advertencia",
    color: "#f87171",
  },
  {
    id: "error",
    name: "Error",
    color: "#f87171",
  },
  {
    id: "fatal",
    name: "Error fatal",
    color: "#f87171",
  },
];

export const INCIDENT_SEVERITIES_MAP = new Map(
  INCIDENT_SEVERITIES.map((is) => [is.id, is])
);
