// RotateMap.tsx
import { useEffect } from "react";
import { useMap } from "react-leaflet";
// Import the plugin so it attaches to Leaflet’s Map prototype
import "leaflet-rotate";

interface RotateMapProps {
  angle: number;
}

const RotateMap = ({ angle }: RotateMapProps) => {
  const map = useMap();

  useEffect(() => {
    // Check if the plugin method exists
    if ((map as any).setRotationAngle) {
      (map as any).setRotationAngle(angle);
    } else {
      console.warn(
        "setRotationAngle is not available. Make sure leaflet-rotate is installed and compatible."
      );
    }
  }, [map, angle]);

  return null;
};

export default RotateMap;
