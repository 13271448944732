import { useRef, useState } from "react";
import Navbar from "components/navbar";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { createWifiZoneReportData } from "api/wifi-zone-report-data/create-wifi-zone-report-data";
import { CreateWifiZoneReportDataDto } from "api/wifi-zone-report-data/dto/create-wifi-zone-report-data.dto";
import WifiReportChart from "components/wifi-report-chart";

const BATCH_SIZE = 500;

/** Utility to break an array into subarrays of a given size */
function chunkArray<T>(arr: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
}

export default function WifiReport() {
  const [data, setData] = useState<CreateWifiZoneReportDataDto[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // On button click -> trigger hidden file input
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  // Main logic to read, parse, chunk, and upload in batches
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array", cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert worksheet to JSON
      const rows = XLSX.utils.sheet_to_json<any>(worksheet);

      // Transform each row into CreateWifiZoneReportDataDto shape
      const reportData: CreateWifiZoneReportDataDto[] = rows.map(
        (row: any) => ({
          timestamp: new Date(row["Timestamp"] || Date.now()),
          ageRange: String(row["Rango Edad"] || ""),
          gender: String(row["Genero"] || ""),
          nationality: String(row["Nacionalidad"] || ""),
          sensorId: String(row["idwifi"] || ""),
          zoneName: String(row["nombrewifi"] || ""),
        })
      );

      // Chunk the data into subarrays of BATCH_SIZE
      const chunks = chunkArray(reportData, BATCH_SIZE);

      // Upload each chunk in sequence, clearing DB only on the first chunk
      for (let i = 0; i < chunks.length; i++) {
        const isFirstChunk = i === 0;
        const chunk = chunks[i];

        // Make the API call:
        // createWifiZoneReportData() is an API call that:
        //   1) sends POST to /wifi-zone-report-data?clear=${isFirstChunk}
        //   2) body is the chunk array
        const [_result, ok] = await createWifiZoneReportData(
          chunk,
          isFirstChunk
        );

        if (!ok) {
          toast.error(`Error subiendo datos en el batch #${i + 1}`);
          // Optionally break or return if you want to stop on failure
          return;
        } else {
          // Optional: progress feedback
          toast.success(`Batch #${i + 1} subido con éxito`);
        }

        setData(reportData);
      }

      // All chunks uploaded successfully
      toast.success("Todos los datos se han subido correctamente.");
    } catch (error) {
      console.error("Error reading/parsing the XLSX file", error);
      toast.error("Ocurrió un error procesando el archivo.");
    } finally {
      // Clear input so user can re-upload if needed
      event.target.value = "";
    }
  };

  return (
    <div className="flex flex-col bg-[#1c295d] min-h-screen">
      <Navbar />
      <div className="flex flex-col bg-[#fbb930] items-center pt-4 flex-1 w-screen text-black">
        <div className="max-w-6xl w-[80%] min-w-[300px] flex flex-col bg-white/70 rounded-lg p-4 gap-2">
          <div className="flex gap-2 items-center">
            <h1 className="text-2xl font-bold">Reporte Zonas Wifi</h1>
            <div className="flex-auto"></div>
            <button
              onClick={handleUploadClick}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Cargar reporte
            </button>
          </div>

          {/* Hidden file input */}
          <input
            type="file"
            accept=".xlsx,.xls"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />

          <WifiReportChart data={data} />
        </div>
      </div>
    </div>
  );
}
