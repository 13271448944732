import { getSensorCountsByType } from "api/sensor/get-sensor-counts-by-type";
import { SENSOR_TYPES } from "constants/sensor-types";
import { useEffect, useState } from "react";

const EXCLUDED_SENSOR_TYPES = ["hotel", "restaurant"];
export const SensorCounters = () => {
  const [counters, setCounters] = useState<{ name: string; count: number }[]>(
    []
  );

  useEffect(() => {
    getSensorCountsByType().then(([stats, ok]) => {
      if (!ok) return;

      setCounters(
        SENSOR_TYPES.filter(
          (st) =>
            !EXCLUDED_SENSOR_TYPES.includes(st.id) &&
            Object.keys(stats).includes(st.id)
        ).map((st) => ({
          name: st.name,
          count: stats[st.id],
        }))
      );
    });
  }, []);

  return (
    <div className="flex gap-2 flex-wrap">
      {counters.map((cnt, i) => (
        <div
          key={i}
          className="w-[180px] text-black flex flex-col items-center justify-center px-4 py-2 rounded-lg shadow-lg bg-white"
        >
          <div className="text-4xl font-bold ">{cnt.count}</div>
          <div>{cnt.name}</div>
        </div>
      ))}
    </div>
  );
};
