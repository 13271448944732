import { useEffect, useState } from 'react';

export const useGoogleMaps = (apiKey: string) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    // If Google Maps is already available, we're done.
    if ((window as any).google && (window as any).google.maps) {
      setLoaded(true);
      return;
    }

    // Check if the script is already in the document.
    const existingScript = document.querySelector(
      `script[src*="maps.googleapis.com/maps/api/js"]`
    );

    if (existingScript) {
      // If the script is already loaded, set loaded to true.
      if (existingScript.getAttribute("data-loaded") === "true") {
        setLoaded(true);
      } else {
        // Otherwise, wait for it to load.
        existingScript.addEventListener("load", () => {
          existingScript.setAttribute("data-loaded", "true");
          setLoaded(true);
        });
      }
      return;
    }

    // Create and append the script.
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      script.setAttribute("data-loaded", "true");
      setLoaded(true);
    };
    script.onerror = () => console.error("Failed to load Google Maps");
    document.head.appendChild(script);

    // Do not remove the script on cleanup to avoid re-loading.
  }, [apiKey]);

  return loaded;
};
