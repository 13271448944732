import { uploadFile } from "api/file/upload";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

interface UploadMultipleFilesProps {
  imageUrls: string[];
  onFilesUploaded: (newFileNames: string[]) => void;
}

export default function UploadMultipleFiles({
  onFilesUploaded,
  imageUrls,
}: UploadMultipleFilesProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

  async function handleFilesUpload(files: FileList) {
    const fileArray = Array.from(files);
    const newFileNames: string[] = [];

    for (const file of fileArray) {
      const [result, ok] = await uploadFile(file);
      if (ok) {
        toast.success("Archivo subido correctamente");
        newFileNames.push(result.fileName);
      } else {
        toast.error(
          "Error al subir archivo, verifique el tamaño (máximo 1mb) y formato"
        );
      }
    }

    const updatedFiles = [...uploadedFiles, ...newFileNames];
    setUploadedFiles(updatedFiles);
    onFilesUploaded(updatedFiles);
  }

  return (
    <div className="flex flex-col gap-2">
      <input
        type="file"
        id="import-multiple-files"
        accept=".jpg,.jpeg,.png"
        multiple
        style={{ display: "none" }}
        onChange={(e) => {
          if (!e.target.files) return;
          e.preventDefault();
          handleFilesUpload(e.target.files);
        }}
        ref={fileInputRef}
      />
      <button
        className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold"
        onClick={(e) => {
          e.preventDefault();
          fileInputRef.current?.click();
        }}
      >
        Subir archivos
      </button>

      {uploadedFiles.length > 0 && (
        <div className="flex gap-2 flex-wrap">
          {uploadedFiles.map((fileName, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${fileName}`}
              alt={`Uploaded ${index}`}
              className="w-[50px] h-[50px] rounded-lg object-cover"
            />
          ))}
        </div>
      )}

      {imageUrls && uploadedFiles.length === 0 && (
        <div className="flex gap-2 flex-wrap">
          {imageUrls.map((fileName, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${fileName}`}
              alt={`Uploaded ${index}`}
              className="w-[50px] h-[50px] rounded-lg object-cover"
            />
          ))}
        </div>
      )}
    </div>
  );
}
