import { CreateRangeDto } from "api/variables/dto/create-range.dto";
import { VariableDto } from "api/variables/dto/variable.dto";
import { updateVariable } from "api/variables/update-variable";
import { useState } from "react";
import RangesForm from "./ranges-form";

export default function UpdateVariableForm({
  variable,
  onVariableUpdated,
}: {
  variable: VariableDto;
  onVariableUpdated: () => void;
}) {
  const [name, setName] = useState(variable.name);
  const [title, setTitle] = useState(variable.title);
  const [unit, setUnit] = useState(variable.unit);
  const [useRanges, setUseRanges] = useState(variable.ranges.length > 0);
  const [ranges, setRanges] = useState<CreateRangeDto[]>(variable.ranges);
  const [error, setError] = useState("");
  const [kunakMapping, setKunakMapping] = useState(variable.kunakMapping);
  const [unitConversionFormula, setUnitConversionFormula] = useState(
    variable.unitConversionFormula
  );
  const [minValidValue, setMinValidValue] = useState<number | undefined>(
    variable.minValidValue
  );
  const [maxValidValue, setMaxValidValue] = useState<number | undefined>(
    variable.maxValidValue
  );

  const handleUpdateVariable = async () => {
    setError("");

    // We must check if 'name' follows the format of a valid variable name
    // before sending it to the backend, using a regular expression
    if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(name)) {
      setError("El nombre de la variable no es válido");
      return;
    }

    try {
      const result = await updateVariable(variable._id, {
        name,
        title,
        unit,
        useRanges,
        ranges,
        kunakMapping,
        unitConversionFormula,
        minValidValue,
        maxValidValue,
      });

      if (!result.ok) {
        setError("Hubo un error al actualizar la variable");
        return;
      }

      onVariableUpdated();
    } catch (e) {
      setError("Hubo un error al actualizar la variable");
    }
  };

  return (
    <div className="flex gap-2 flex-col p-2 text-black h-[80vh] max-h-[950px] overflow-y-auto overflow-hidden">
      <div className="flex flex-col gap-1">
        <label>Nombre subida plataforma mompox</label>
        <input
          type="text"
          placeholder="Nombre"
          value={name}
          className="p-2 rounded-lg border"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Nombre subida plataforma kunak</label>
        <input
          type="text"
          placeholder="Nombre"
          value={kunakMapping}
          className="p-2 rounded-lg border"
          onChange={(e) => setKunakMapping(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Título (para mostrar en reportes)</label>
        <input
          type="text"
          placeholder="Título"
          value={title}
          className="p-2 rounded-lg border"
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Unidad</label>
        <input
          type="text"
          placeholder="Unidad"
          value={unit}
          className="p-2 rounded-lg border"
          onChange={(e) => setUnit(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Mínimo valor válido</label>
        <input
          type="number"
          placeholder="Mínimo valor válido"
          value={minValidValue}
          className="p-2 rounded-lg border"
          onChange={(e) => {
            const value: number = parseFloat(e.target.value);

            if (isNaN(value)) return;

            setMinValidValue(value);
          }}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Máximo valor válido</label>
        <input
          type="number"
          placeholder="Máximo valor válido"
          value={maxValidValue}
          className="p-2 rounded-lg border"
          onChange={(e) => {
            const value: number = parseFloat(e.target.value);

            if (isNaN(value)) return;

            setMaxValidValue(value);
          }}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Fórmula para conversión de Unidad</label>
        <textarea
          placeholder="Fórmula para conversión de Unidad"
          value={unitConversionFormula}
          className="p-2 rounded-lg border"
          onChange={(e) => setUnitConversionFormula(e.target.value)}
        ></textarea>
      </div>
      <details className="p-4 border border-gray-300 rounded-lg bg-gray-50">
        <summary className="font-semibold cursor-pointer">
          Cómo definir la fórmula para conversión de unidad
        </summary>
        <div className="mt-2 text-gray-700">
          <p>
            La fórmula es una expresión que toma el dato en una variable llamada
            "datum" y mediante el uso de operaciones aritméticas lo convierte a
            la unidad deseada. Esta variable "datum" no hace referencia a la
            medición de ésta variable en particular, sino a un objeto que
            contiene todas las mediciones de todas las variables para un
            determinado sensor en una fecha particular.
          </p>
          <p>
            Por ejemplo, digamos que un sensor que mide temperatura y humedad,
            entonces la variable "datum" puede verse algo así:
            <code>
              {"{"}
              "temperature": 137, "humidity": 20
              {"}"}
            </code>
          </p>

          <p>
            Entonces, si queremos convertir la temperatura de Fahrenheit a
            grados centigrados, usaríamos la siguiente fórmula:
            <code>(datum.temperature - 32) * 5/9</code>
          </p>
        </div>
      </details>
      <div className="flex gap-2 items-center">
        <label>Usa rangos?</label>
        <input
          type="checkbox"
          checked={useRanges}
          onChange={(e) => setUseRanges(e.target.checked)}
        />
      </div>

      {useRanges && <RangesForm ranges={ranges} setRanges={setRanges} />}
      {error && <div className="text-red-500">{error}</div>}
      <button
        className="p-2 mompox-blue text-white rounded-lg"
        onClick={handleUpdateVariable}
      >
        Actualizar variable
      </button>
    </div>
  );
}
