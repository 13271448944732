import { INCIDENT_TYPES } from "constants/incident-types";
import { DropdownOption } from "models/dropdown-option.model";
import { createIncident } from "api/incident/create-incident";
import { CreateIncidentDto } from "api/incident/dto/create-incident.dto";
import { useState, FormEvent } from "react";
import Select from "react-select";
import { SENSOR_TYPES } from "constants/sensor-types";
import { INCIDENT_VISIBILITIES } from "constants/incident-visibilities";
import { INCIDENT_SEVERITIES } from "constants/incident-severities";

const incidentTypes: DropdownOption[] = INCIDENT_TYPES.map(
  (it) =>
    ({
      label: it.name,
      value: it.id,
    } as DropdownOption)
);

const visibilityTypes: DropdownOption[] = INCIDENT_VISIBILITIES.map(
  (it) =>
    ({
      label: it.name,
      value: it.id,
    } as DropdownOption)
);

const severityTypes: DropdownOption[] = INCIDENT_SEVERITIES.map(
  (it) =>
    ({
      label: it.name,
      value: it.id,
    } as DropdownOption)
);

export default function CreateIncidentForm({
  onIncidentCreated,
}: {
  onIncidentCreated: () => void;
}) {
  const [type, setType] = useState(SENSOR_TYPES[0].id);
  const [severity, setSeverity] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: CreateIncidentDto = {
      type,
      severity,
      title,
      description,
      visibility,
    };
    // Create the air quality range
    const [, ok] = await createIncident(data);

    if (ok) {
      onIncidentCreated();
      return;
    }

    setError(
      "Hubo un error al crear el rango de calidad del aire, intente de nuevo."
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-black">
      <h2 className="text-2xl text-mompox-blue font-bold">Nuevo registro</h2>
      <div className="max-h-[600px] overflow-y-auto">
        <div>Título</div>
        <input
          type="text"
          className="p-2 rounded-lg border"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          placeholder="Título"
        />
        <div>Descripción</div>
        <input
          type="text"
          className="p-2 rounded-lg border"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          placeholder="Descripción"
        />
        <div>Tipo</div>
        <Select
          options={incidentTypes}
          value={incidentTypes.find((option) => option.value === type)}
          onChange={(selected) => setType(selected!.value)}
          placeholder="Tipo de incidencia"
          className="basic-multi-select"
          classNamePrefix="select"
        />
        <div>Severidad</div>
        <Select
          options={severityTypes}
          value={severityTypes.find((option) => option.value === severity)}
          onChange={(selected) => setSeverity(selected!.value)}
          placeholder="Tipo de incidencia"
          className="basic-multi-select"
          classNamePrefix="select"
        />
        <div>Visibilidad</div>
        <Select
          options={visibilityTypes}
          value={visibilityTypes.find((option) => option.value === visibility)}
          onChange={(selected) => setVisibility(selected!.value)}
          placeholder="Visibilidad"
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
      <button type="submit" className="mompox-blue text-white p-2 rounded-lg">
        Crear registro
      </button>

      {error && <p className="text-red-500 mt-2">{error}</p>}
    </form>
  );
}
