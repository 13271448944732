import { getVariables } from "api/variables/get-variables";
import { getLastSensorData } from "api/orion/sensor-data/get-sensor-data";
import { format } from "date-fns";
import { Sensor } from "models/sensor.model";
import React from "react";
import { FC, useEffect, useState } from "react";
import { VariableDto } from "@api/variables/dto/variable.dto";
import { useAuth } from "hooks/use-auth";
import SensorDescription from "./sensor-description";
import { OnlineIndicator } from "./online-indicator";

export interface SensorPopupProps {
  sensor: Sensor;
  setSelectedSensor: (sensor: Sensor) => void;
  setIsSidePanelOpen: (isOpen: boolean) => void;
}

export function SensorPopup({ sensor }: SensorPopupProps) {
  const { isAdmin } = useAuth();

  return (
    <div className="min-w-[250px]">
      <h3 className="font-black text-lg mb-4">{sensor.name}</h3>
      <OnlineIndicator sensor={sensor} />
      <SensorDescription sensor={sensor} />
      {sensor.imageUrl && (
        <img
          src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${sensor.imageUrl}`}
          alt="Imagen del sensor"
          className="w-full h-auto max-h-[150px] rounded-lg object-cover"
        />
      )}
      {isAdmin && sensor.isMonitoringEnabled && (
        <a href={"/monitoring-report?sensorId=" + sensor._id}>
          <button className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold mt-2">
            Reporte monitoreo
          </button>
        </a>
      )}
    </div>
  );
}

export function TableSensorPopup({
  sensor,
  setSelectedSensor,
  setIsSidePanelOpen,
}: SensorPopupProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [VARIABLES_MAP, setVariablesMap] = useState<Map<string, string>>(
    new Map()
  );
  const { isAdmin } = useAuth();

  useEffect(() => {
    getVariables()
      .then(([variables, ok]) => {
        if (ok) {
          const VARIABLES_MAP = new Map<string, string>(
            variables.map((v: VariableDto) => [
              v.name,
              v.unit ? `${v.title} (${v.unit})` : v.title,
            ])
          );
          setVariablesMap(VARIABLES_MAP);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError("Error al obtener las variables");
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <div>Cargando...</div>;

  if (error) return <div>{error}</div>;

  return (
    <div className="w-full">
      <h3 className="font-black text-sm mb-4">{sensor.name}</h3>
      <OnlineIndicator sensor={sensor} />
      <SensorDescription sensor={sensor} />
      {sensor.imageUrl && (
        <img
          src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${sensor.imageUrl}`}
          alt="Imagen del sensor"
          className="w-full h-auto max-h-[150px] rounded-lg object-cover"
        />
      )}

      <SensorLastDatumTable
        sensorId={sensor._id}
        variables={sensor.variables.split(",")}
        variablesMap={VARIABLES_MAP}
      ></SensorLastDatumTable>

      <button
        onClick={() => {
          setSelectedSensor(sensor);
          setIsSidePanelOpen(true);
        }}
        className="mt-4 mompox-blue text-white px-4 py-1 rounded w-full"
      >
        Ver datos
      </button>

      {isAdmin && sensor.isMonitoringEnabled && (
        <a href={"/monitoring-report?sensorId=" + sensor._id}>
          <button className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold mt-2">
            Reporte monitoreo
          </button>
        </a>
      )}
    </div>
  );
}

interface SensorLastDatumTableProps {
  sensorId: string;
  variables: string[];
  variablesMap: Map<string, string>;
}

const SensorLastDatumTable: FC<SensorLastDatumTableProps> = ({
  sensorId,
  variables,
  variablesMap,
}: SensorLastDatumTableProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [sensorData, setSensorData] = useState<any | null>();

  useEffect(() => {
    getLastSensorData(sensorId)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const datum = data;
            setSensorData(datum ?? null);
            setIsLoading(false);
          });
        } else {
          setErrorMsg("Error al obtener los datos del sensor");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setErrorMsg("Error al obtener los datos del sensor");
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <div>Cargando...</div>;

  if (errorMsg) return <div>{errorMsg}</div>;

  if (!sensorData) return <div>No hay datos disponibles</div>;

  return (
    <div className="w-full mt-4 grid grid-cols-[1fr,100px] text-[9px]">
      <div className="border-b border-b-1 border-b-black/50 col-span-2 text-center p-1 font-bold">
        Último dato del sensor
      </div>
      <div className="border-b border-b-1 border-b-black/50  p-1 font-bold">
        Variable
      </div>
      <div className="border-b border-b-1 border-b-black/50 text-center  p-1 font-bold">
        Valor
      </div>

      {!!sensorData["createdAt"] && (
        <>
          <div className="border-b border-b-1 border-b-black/50 p-1">Fecha</div>
          <div className="border-b border-b-1 border-b-black/50 text-right p-1">
            {format(
              new Date(sensorData["createdAt"]),
              "yyyy-MM-dd HH:mm"
            )}
          </div>
        </>
      )}

      {variables
        .filter(
          (variable) => sensorData[variable] && sensorData[variable]
        )
        .map((variable: any) => (
          <React.Fragment key={variable}>
            <div className="border-b border-b-1 border-b-black/50 p-1">
              {variablesMap.get(variable)}{" "}
              {sensorData[variable]["unit"]
                ? `(${sensorData[variable]["unit"]})`
                : ""}
            </div>
            <div className="border-b border-b-1 border-b-black/50 text-right p-1">
              {Intl.NumberFormat("es-CO").format(sensorData[variable])}
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};
