export const INCIDENT_TYPES = [
  {
    id: "sensor",
    name: "Sensor",
  },
  {
    id: "general",
    name: "General",
  },
];
