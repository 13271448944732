import { Sensor } from "models/sensor.model";
import { useAuth } from "hooks/use-auth";
import SensorDescription from "./sensor-description";
import { OnlineIndicator } from "./online-indicator";
import ImageCarousel from "./image-carousel";

export interface SmartMuralSensorPopupProps {
  sensor: Sensor;
  setSelectedSensor: (sensor: Sensor) => void;
  setIsSidePanelOpen: (isOpen: boolean) => void;
}

export function SmartMuralSensorPopup({ sensor }: SmartMuralSensorPopupProps) {
  const { isAdmin } = useAuth();

  // Build the full URL array for the carousel
  const carouselImages =
    sensor.carouselURLs && sensor.carouselURLs.length > 0
      ? sensor.carouselURLs.map(
          (url) => `${process.env.REACT_APP_API_BASE_URL}/v1/files/${url}`
        )
      : [];

  return (
    <div className="min-w-[250px]">
      <h3 className="font-black text-lg mb-4">{sensor.name}</h3>
      <OnlineIndicator sensor={sensor} />
      <SensorDescription sensor={sensor} />
      {/* Show single image if carouselURLs is not provided */}
      {sensor.imageUrl &&
        (!sensor.carouselURLs || sensor.carouselURLs.length === 0) && (
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${sensor.imageUrl}`}
            alt="Imagen del sensor"
            className="w-full h-auto max-h-[150px] rounded-lg object-cover"
          />
        )}
      {/* Show carousel if carouselURLs is provided */}
      {carouselImages.length > 0 && <ImageCarousel images={carouselImages} />}
      {isAdmin && sensor.isMonitoringEnabled && (
        <a href={"/monitoring-report?sensorId=" + sensor._id}>
          <button className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold mt-2">
            Reporte monitoreo
          </button>
        </a>
      )}
    </div>
  );
}
