import { IncidentDto } from "api/incident/dto/incident.dto";
import { getAllIncidents, getPublicIncidents } from "api/incident/get-incidents";
import { INCIDENT_SEVERITIES_MAP } from "constants/incident-severities";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { FC, useEffect, useState } from "react";

export default function DashboardIncidentsList() {
  const [incidents, setIncidents] = useState<IncidentDto[]>([]);
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchIncidents();
  }, [startDate, endDate]);

  async function fetchIncidents() {
    const [incidents, ok] = await getAllIncidents({
      startDate: startOfDay(startDate).toISOString(),
      endDate: endOfDay(endDate).toISOString(),
    });

    if (ok) {
      setIncidents(incidents);
    }

    setError("No se pudieron obtener los rangos ICA, intente nuevamente.");
  }

  return (
    <>
      <div className="flex flex-col gap-2 text-black">
        <div>
          <label className="block mb-1">Fecha inicio</label>
          <input
            type="date"
            value={format(startDate, "yyyy-MM-dd")}
            onChange={(e) => setStartDate(new Date(e.target.value))}
            className="border p-2 w-full rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Fecha fin</label>
          <input
            type="date"
            value={format(endDate, "yyyy-MM-dd")}
            onChange={(e) => setEndDate(new Date(e.target.value))}
            className="border p-2 w-full rounded"
          />
        </div>
        {incidents.map((incident) => (
          <IncidentItem key={incident._id} incident={incident} />
        ))}
      </div>
    </>
  );
}

interface IncidentItemProps {
  incident: IncidentDto;
}

const IncidentItem: FC<IncidentItemProps> = ({ incident }) => {
  return (
    <div
      className={`flex flex-col shadow-lg gap-1 border-l-[${
        INCIDENT_SEVERITIES_MAP.get(incident.severity)!.color
      }] bg-white border-l-4 text-black p-2 rounded-lg`}
    >
      <div>{incident.title}</div>
      <div>{incident.description}</div>
    </div>
  );
};
