import { Sensor } from "models/sensor.model";

interface OnlineIndicatorProps {
  sensor: Sensor;
}

/**
 * Renders a green/red dot depending on the sensor's online status
 */
export function OnlineIndicator({ sensor }: OnlineIndicatorProps) {
  if (!sensor.isMonitoringEnabled) {
    return <div></div>;
  }

  return (
    <div className="flex items-center my-2">
      {sensor.isOnline ? (
        <div className="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
      ) : (
        <div className="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
      )}
      <span className="text-sm">
        {sensor.isOnline ? "En línea" : "En desconexión"}
      </span>
    </div>
  );
}
