import DashboardIncidentsList from "components/dashboard-incident-list";
import ReportForm from "components/forms/report-form";
import MapComponent from "components/map";
import Navbar from "components/navbar";
import { SensorCounters } from "components/sensor-counters";
import { useState } from "react";

export default function Dashboard() {
  const [currentTab, setCurrentTab] = useState("report");

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div className="w-full flex flex-col bg-[#fbb930] p-4 h-[calc(100vh-56px)] gap-2">
        <div className="flex items-center mb-4">
          <h1 className="text-2xl font-bold text-mompox-blue">Dashboard</h1>
        </div>
        <SensorCounters></SensorCounters>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 items-center overflow-hidden h-full">
          <div className="h-full overflow-y-auto">
            <div className="tab">
              <button
                className={
                  currentTab === "report" ? "tablinks active" : "tablinks"
                }
                onClick={() => setCurrentTab("report")}
              >
                Reporte
              </button>
              <button
                className={
                  currentTab === "incidents" ? "tablinks active" : "tablinks"
                }
                onClick={() => setCurrentTab("incidents")}
              >
                Incidencias
              </button>
            </div>

            {currentTab === "report" && <ReportForm dashboardMode />}
            {currentTab === "incidents" && <DashboardIncidentsList />}
          </div>

          <MapComponent hideMenu disableRotation />
        </div>
      </div>
    </div>
  );
}
