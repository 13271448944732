import { ApiResponse } from "@models/api-response";
import { IncidentDto } from "./dto/incident.dto";
import { subDays } from "date-fns";

export async function getPublicIncidents(
  filters = {
    startDate: subDays(new Date(), 1).toISOString(),
    endDate: new Date().toISOString(),
  }
): Promise<ApiResponse<IncidentDto[]>> {
  try {
    const params = new URLSearchParams();
    params.set("startDate", filters.startDate);
    params.set("endDate", filters.endDate);
    params.set("limit", "1");
    params.set("page", "1");
    params.set("limit", "1000");
    params.set("sortColumn", "createdAt");
    params.set("sortDirection", "desc");

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/incidents/public?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    const result = await response.json();
    return [result, true];
  } catch (error) {
    return [null, false];
  }
}

export async function getAllIncidents(
  filters = {
    startDate: subDays(new Date(), 1).toISOString(),
    endDate: new Date().toISOString(),
  }
): Promise<ApiResponse<IncidentDto[]>> {
  try {
    const params = new URLSearchParams();
    params.set("startDate", filters.startDate);
    params.set("endDate", filters.endDate);
    params.set("limit", "1");
    params.set("page", "1");
    params.set("limit", "1000");
    params.set("sortColumn", "createdAt");
    params.set("sortDirection", "desc");

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/incidents?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    const result = await response.json();
    return [result, true];
  } catch (error) {
    return [null, false];
  }
}

