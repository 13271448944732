import { ApiResponse } from "@models/api-response";

export async function getRawSensorData(
  sensorId: string,
  startDate: string,
  endDate: string
): Promise<ApiResponse<any[]>> {
  try {
    const url = `${
      process.env.REACT_APP_API_BASE_URL
    }/v1/sensor-data/raw-data?startDate=${encodeURIComponent(
      startDate
    )}&endDate=${encodeURIComponent(endDate)}&sensorId=${encodeURIComponent(
      sensorId
    )}`;

    // Fetch the CSV data as a blob
    const result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    });

    if (!result.ok) {
      console.error("Error fetching sensor data:", result.statusText);
      return [null, false];
    }

    const data = await result.json();
    return [data, true];
  } catch (error) {
    console.error("Error fetching sensor data:", error);
    return [null, false];
  }
}
