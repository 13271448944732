import { DropdownOption } from "models/dropdown-option.model";
import { Sensor } from "models/sensor.model";
import Navbar from "components/navbar";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { useEffect, useState } from "react";
import Select from "react-select";
import { getRawSensorData } from "api/sensor/get-raw-sensor-data";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

export default function DataImport() {
  const [selectedSensor, setSelectedSensor] = useState<DropdownOption | null>(
    null
  );
  const [availableSensors, setAvailableSensors] = useState<DropdownOption[]>(
    []
  );
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const [isExportingData, setIsExportingData] = useState(false);

  useEffect(() => {
    fetchAvailableSensors();
  }, []);

  const fetchAvailableSensors = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/v1/sensors`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setAvailableSensors(
          data.map((d: Sensor) => ({ label: d.name, value: d._id }))
        );
      } else {
        console.error("Error al obtener los sensores");
      }
    } catch (error) {
      console.error("Error al obtener los sensores:", error);
    }
  };

  // Exports all data in time interval
  async function exportData() {
    if (!selectedSensor) return;
    const [data, ok] = await getRawSensorData(
      selectedSensor.value,
      startOfDay(startDate).toISOString(),
      endOfDay(endDate).toISOString()
    );

    setIsExportingData(false);
    if (!ok) {
      toast.error("Error al exportar datos, intente más tarde");
      return;
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Datos");
    XLSX.writeFile(wb, "datos.xlsx");
  }

  // Updates data in batches of 1000 records
  async function updateData() {
    if (
      !window.confirm(
        "Esta operación va a sobreescribir los datos del sensor utilizando los provistos en el archivo excel, ¿está seguro?."
      )
    )
      return;

    toast.info('Funcionalidad en desarrollo');
  }

  return (
    <>
      <div className="flex flex-col bg-[#1c295d]">
        <Navbar />
        <div className="flex flex-col bg-[#fbb930] items-center pt-4 h-[calc(100vh-56px)] w-screen text-black">
          <div className="max-w-6xl w-[80%] min-w-[300px] flex flex-col bg-white/70 rounded-lg p-4 gap-2">
            <div className="flex items-center mb-4">
              <h1 className="text-2xl font-bold text-mompox-blue">
                Exportación de datos
              </h1>
            </div>

            <div>
              <div>
                <label className="block mb-1">Fecha inicio</label>
                <input
                  type="date"
                  value={format(startDate, "yyyy-MM-dd")}
                  onChange={(e) => setStartDate(new Date(e.target.value))}
                  className="border p-2 w-full rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Fecha fin</label>
                <input
                  type="date"
                  value={format(endDate, "yyyy-MM-dd")}
                  onChange={(e) => setEndDate(new Date(e.target.value))}
                  className="border p-2 w-full rounded"
                />
              </div>
              <label className="block mb-1">Sensor</label>
              <Select
                options={availableSensors}
                value={availableSensors.find(
                  (option) => option.value === selectedSensor?.value
                )}
                onChange={(selected) => setSelectedSensor(selected)}
                placeholder="Sensores"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>

            <div className="flex gap-2">
              <button
                disabled={isExportingData}
                onClick={() => exportData()}
                className="self-start mompox-light-blue text-white p-2 rounded-lg"
              >
                Exportar datos
              </button>

              {/* <button
                disabled={isExportingData}
                onClick={() => updateData()}
                className="self-start mompox-light-blue text-white p-2 rounded-lg"
              >
                Actualizar datos masivamente
              </button> */}
            </div>

            {/* <p>
              Para la actualización masiva es necesario que la columna _id esté
              presente, sino no es posible.
            </p> */}

            {isExportingData && "Exportando datos..."}
          </div>
        </div>
      </div>
    </>
  );
}
