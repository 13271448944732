import { ApiResponse } from "@models/api-response";
import { UpdateIncidentDto } from "./dto/update-incident.dto";
import { IncidentDto } from "./dto/incident.dto";

export async function updateIncident(
  id: string,
  data: UpdateIncidentDto
): Promise<ApiResponse<IncidentDto>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/incidents/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    return [{} as any, true];
  } catch (error) {
    return [null, false];
  }
}
