import { Sensor } from "models/sensor.model";
import SensorDescription from "./sensor-description";
import { useAuth } from "hooks/use-auth";
import { OnlineIndicator } from "./online-indicator";

export interface WifiPopupProps {
  sensor: Sensor;
  setSelectedSensor: (sensor: Sensor) => void;
  setIsSidePanelOpen: (isOpen: boolean) => void;
}

export function WifiPopup({
  sensor,
  setSelectedSensor,
  setIsSidePanelOpen,
}: WifiPopupProps) {
  const { isAdmin } = useAuth();

  return (
    <div className="min-w-[250px]">
      <h3 className="font-black text-lg mb-4">{sensor.name}</h3>
      <OnlineIndicator sensor={sensor} />
      <SensorDescription sensor={sensor} />
      {sensor.imageUrl && (
        <img
          src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${sensor.imageUrl}`}
          alt="Imagen del sensor"
          className="w-full h-auto max-h-[150px] rounded-lg object-cover"
        />
      )}
      <button
        onClick={() => {
          setSelectedSensor(sensor);
          setIsSidePanelOpen(true);
        }}
        className="mt-4 mompox-blue text-white px-4 py-1 rounded w-full"
      >
        Estadísticas de uso
      </button>
      {isAdmin && sensor.isMonitoringEnabled && (
        <a href={"/monitoring-report?sensorId=" + sensor._id}>
          <button className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold mt-2">
            Reporte monitoreo
          </button>
        </a>
      )}
    </div>
  );
}

export default WifiPopup;
