"use client";
import { MapContainer, Marker, Popup, useMap } from "react-leaflet";
import L, { Icon, MapOptions } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.gridlayer.googlemutant";
import { FC, useEffect, useState } from "react";
import { MapStyle } from "./map-style";
import { getSensorDataCSV } from "api/sensor/get-csv-data";
import { format, subDays } from "date-fns";
import { SensorLayer } from "models/sensor-layer.model";
import {
  SENSOR_TYPE_MARKER_ICON_MAP,
  SENSOR_TYPE_OFFLINE_MARKER_ICON_MAP,
  SENSOR_TYPE_POPUP_MAP,
  SENSOR_TYPES,
} from "constants/sensor-types";
import { Sensor } from "models/sensor.model";
import LayerMenu from "./layer-menu";
import { useGoogleMaps } from "hooks/use-google-maps";
import { SensorPopup, SensorPopupProps } from "./forms/sensor/sensor-popup";
import ReportForm from "./forms/report-form";
import WifiReportChart from "./wifi-report-chart";
import { useAuth } from "hooks/use-auth";
import RotateMap from "./rotate-map";
import { DroneCard } from "./drone-card";

const publicOnlyLayerTypes = ["hotel", "restaurant"];
const adminLoggedInLayerTypes = ["cctv", "drone"];

// Extend MapOptions with leaflet-rotate options
interface ExtendedMapOptions extends MapOptions {
  rotate?: boolean;
  rotateControl?: object;
  bearing?: number;
  touchRotate?: boolean;
}

const mapOptions: ExtendedMapOptions = {
  center: [9.247592, -74.429925],
  zoom: 15.4,
  // Pass the leaflet-rotate options directly:
  rotate: true,
  rotateControl: { closeOnZeroBearing: false },
  bearing: -80,
  touchRotate: true,
};

interface MapComponentProps {
  hideMenu?: boolean;
  disableRotation?: boolean;
}

const MapComponent = ({ hideMenu, disableRotation }: MapComponentProps) => {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [unfilteredLayers, setUnfilteredLayers] = useState<SensorLayer[]>([]);
  const [layers, setLayers] = useState<SensorLayer[]>([]);

  const [layerStatuses, setLayerStatuses] = useState<{
    [key: string]: boolean;
  }>(
    SENSOR_TYPES.reduce(
      (acc, type) => ({ ...acc, [type.id]: type.id !== "drone" }),
      {}
    )
  );

  const [filteredSensors, setFilteredSensors] = useState<Sensor[]>([]);

  const { isAdmin, isLoggedIn } = useAuth();

  const [droneSensor, setDroneSensor] = useState<Sensor | null>(null);

  useEffect(() => {
    const activeSensors = sensors.filter(
      (sensor) => !!layerStatuses[`${sensor.type}`]
    );
    setFilteredSensors(activeSensors);
    const droneSensor = activeSensors.find((sensor) => sensor.type === "drone");
    if (!droneSensor) {
      setDroneSensor(null);
      return;
    }
    setDroneSensor(droneSensor);
  }, [layerStatuses, sensors]);

  // Obtener sensores al montar el componente
  useEffect(() => {
    const fetchSensors = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/v1/sensors`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setSensors(data);

          const newLayers = SENSOR_TYPES.map(
            (type) =>
              ({
                id: type.id,
                name: type.name,
                sensors: data.filter(
                  (sensor: Sensor) => sensor.type === type.id
                ),
                icon: type.icon,
                layerType: type.id,
              } satisfies SensorLayer)
          );

          setUnfilteredLayers(newLayers);
        } else {
          console.error("Error al obtener los sensores");
        }
      } catch (error) {
        console.error("Error al obtener los sensores:", error);
      }
    };

    fetchSensors();
  }, []);

  useEffect(() => {
    function getFilteredLayers(layers: SensorLayer[]) {
      console.log("isLoggedIn=", isLoggedIn, "isAdmin=", isAdmin);
      return layers
        .filter((layer) =>
          isLoggedIn ? !publicOnlyLayerTypes.includes(layer.layerType) : true
        )
        .filter((layer) =>
          isAdmin ? true : !adminLoggedInLayerTypes.includes(layer.layerType)
        );
    }

    setLayers(getFilteredLayers(unfilteredLayers));
  }, [isAdmin, isLoggedIn, unfilteredLayers]);

  return (
    <div className="relative h-full w-full overflow-hidden">
      {isAdmin && droneSensor && droneSensor.droneIframeUrl && (
        <DroneCard url={droneSensor.droneIframeUrl}></DroneCard>
      )}

      <MapContainer
        {...(disableRotation ? { ...mapOptions, bearing: 0 } : mapOptions)}
        style={{
          width: "100%",
          height: disableRotation ? "100%" : "200%",
          top: disableRotation ? 0 : "-50%",
        }}
        className="z-0"
      >
        {!disableRotation && <RotateMap angle={90} />}
        <GoogleMutantLayer />
        {/* Mostrar sensores como marcadores */}
        {filteredSensors
          .filter((sensor) =>
            isLoggedIn ? !publicOnlyLayerTypes.includes(sensor.type) : true
          )
          .filter((sensor) =>
            isAdmin ? true : !adminLoggedInLayerTypes.includes(sensor.type)
          )
          .map((sensor) => {
            const CurrentSensorPopup: FC<SensorPopupProps> =
              SENSOR_TYPE_POPUP_MAP.get(sensor.type) || SensorPopup;
            const currentSensorMarkerIconUrl =
              (sensor.isOnline === false
                ? SENSOR_TYPE_OFFLINE_MARKER_ICON_MAP
                : SENSOR_TYPE_MARKER_ICON_MAP
              ).get(sensor.type) ?? "marker.svg";

            const icon = new Icon({
              className: "marker-icon",
              iconUrl: currentSensorMarkerIconUrl,
              iconSize: [48, 48],
              iconAnchor: [24, 48],
              popupAnchor: [0, -48],
            });

            return (
              <Marker
                key={sensor._id}
                position={[sensor.latitude, sensor.longitude]}
                icon={icon}
              >
                <Popup maxWidth={380} minWidth={380}>
                  <CurrentSensorPopup
                    sensor={sensor}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                    setSelectedSensor={setSelectedSensor}
                  ></CurrentSensorPopup>
                </Popup>
              </Marker>
            );
          })}
      </MapContainer>

      {layers && layers.length && !hideMenu && (
        <div className="fixed top-[56px] bottom-0 right-0 h-[calc(100%-56px)] w-fit z-40 text-black flex items-center">
          <LayerMenu
            layers={layers}
            layerStatuses={layerStatuses}
            onLayerToggle={(layer: string) => {
              setLayerStatuses({
                ...layerStatuses,
                [layer]: !layerStatuses[layer],
              });
            }}
          />
        </div>
      )}

      {/* Renderizar la Modal si está abierta */}
      {isSidePanelOpen && selectedSensor && (
        <SensorModal
          sensor={selectedSensor}
          onClose={() => setIsSidePanelOpen(false)}
        />
      )}
    </div>
  );
};

export default MapComponent;

/** Layer to use Google Maps style in Leaflet */
const GoogleMutantLayer = () => {
  const googleMapsApiKey = "AIzaSyCL0mNEE127NGClEWk7b6y5oSL6qq1weps";
  const googleMapsLoaded = useGoogleMaps(googleMapsApiKey);
  const map = useMap();

  useEffect(() => {
    if (googleMapsLoaded) {
      // eslint-disable-next-line
      const googleMutantLayer = (L.gridLayer as unknown as any).googleMutant({
        type: "roadmap",
        styles: MapStyle.light,
        maxZoom: 20,
        apiKey: googleMapsApiKey,
      });
      googleMutantLayer.addTo(map);
    }
  }, [map, googleMapsLoaded]);

  return null;
};

/** Modal component replacing the side panel */
const SensorModal = ({
  sensor,
  onClose,
}: {
  sensor: Sensor;
  onClose: () => void;
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-[90vw] max-w-[1200px] h-[80vh] max-h-[900px] bg-white p-4 text-black overflow-auto shadow-lg rounded">
        <button
          onClick={onClose}
          className="sticky top-2 z-10 flex justify-end w-full"
        >
          <div className="bg-red-100 px-1 shadow rounded-full">✕</div>
        </button>

        {/* Pass the single sensor to the ReportForm */}
        {sensor.type !== "wifi-zone" && <ReportForm sensor={sensor} />}

        {sensor.type === "wifi-zone" && <WifiReportChart sensor={sensor} />}
      </div>
    </div>
  );
};
