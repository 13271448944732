import { Variable } from "models/variable.model";
import CreateVariableForm from "components/forms/variable/create-variable-form";
import UpdateVariableForm from "components/forms/variable/update-variable-form";
import Navbar from "components/navbar";
import { useState, useEffect } from "react";
import { getVariables } from "api/variables/get-variables";
import { deleteVariable } from "api/variables/delete-variable";
import { importVariables } from "api/variables/create-variables";
import { VARIABLES } from "constants/variables";

export default function VariablesList() {
  const [variables, setVariables] = useState<Variable[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState<Variable | null>(
    null
  );

  useEffect(() => {
    fetchVariables();
  }, []);

  async function fetchVariables() {
    const [variables, ok] = await getVariables();

    if (!ok) {
      return;
    }

    setVariables(variables);
  }

  const handleDelete = async (id: string) => {
    const response = await deleteVariable(id);

    if (response.ok) {
      fetchVariables();
    } else {
      console.error("Failed to delete variable");
    }
  };

  const handleUpdate = async (variable: Variable) => {
    setSelectedVariable(variable);
    setIsUpdateModalOpen(true);
  };

  const handleVariableCreated = () => {
    // Refresh the variables list after a new variable is created
    fetchVariables();
    setIsCreateModalOpen(false); // Close the modal after creation
  };

  const handleVariableUpdated = () => {
    // Refresh the variables list after a variable is updated
    fetchVariables();
    setIsUpdateModalOpen(false); // Close the modal after update
  };

  async function importDefaultVariables() {
    await importVariables(
      VARIABLES.map((variable) => ({
        name: variable.value,
        title: variable.label,
        unit: variable.unit,
        useRanges: false,
        ranges: [],
        kunakMapping: "",
      }))
    )
      .then(() => fetchVariables())
      .catch(() => console.error("Failed to import default variables"));
  }

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div className="flex flex-col bg-[#fbb930] items-center pt-4 h-[calc(100vh-56px)] w-screen text-black">
        <div className="max-w-6xl w-[80%] min-w-[300px] flex flex-col bg-white/70 rounded-lg p-4 gap-2">
          <div className="flex gap-2 items-center">
            <h1 className="font-2xl font-bold">Variables</h1>
            <div className="flex-auto"></div>
            <button
              className="mompox-blue text-white p-2 rounded-lg"
              onClick={() => setIsCreateModalOpen(true)}
            >
              Nueva variable
            </button>
          </div>
          {variables.length === 0 && (
            <button
              className="border-[#223a65] border-2 p-1 text-xs rounded-lg w-fit"
              onClick={importDefaultVariables}
            >
              Importar variables por defecto
            </button>
          )}
          <table className="table mt-4">
            <thead>
              <tr>
                <th className="text-left">Nombre</th>
                <th className="text-left">Título</th>
                <th className="w-[80px]">Unidad</th>
                <th className="w-[120px]">Usa rangos</th>
                <th className="w-[120px]">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {variables.map((variable) => (
                <tr key={variable._id}>
                  <td>{variable.name}</td>
                  <td>{variable.title}</td>
                  <td className="text-center">{variable.unit}</td>
                  <td className="text-center">
                    {variable.useRanges ? "Yes" : "No"}
                  </td>
                  <td>
                    <div className="flex gap-2">
                      <button
                        className="border-[#223a65] border-2 p-1 rounded-lg"
                        onClick={() => handleUpdate(variable)}
                      >
                        Editar
                      </button>
                      <button
                        className="text-red-500 p-1 rounded-lg"
                        onClick={() => handleDelete(variable._id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Create Modal */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => setIsCreateModalOpen(false)}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <CreateVariableForm onVariableCreated={handleVariableCreated} />
          </div>
        </div>
      )}
      {/* Edit Modal */}
      {isUpdateModalOpen && selectedVariable && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => {
                setIsUpdateModalOpen(false);
                setSelectedVariable(null);
              }}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <UpdateVariableForm
              variable={selectedVariable}
              onVariableUpdated={handleVariableUpdated}
            />
          </div>
        </div>
      )}
    </div>
  );
}
