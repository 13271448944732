import React, { useEffect, useState, useCallback, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { toast } from "react-toastify";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import { CreateWifiZoneReportDataDto } from "api/wifi-zone-report-data/dto/create-wifi-zone-report-data.dto";
import { getWifiZoneReportData } from "api/wifi-zone-report-data/get-wifi-zone-report-data";
import { Sensor } from "models/sensor.model";
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";

interface WifiReportChartProps {
  sensor?: Sensor;
  // wifiZones: Sensor[];
  data?: CreateWifiZoneReportDataDto[];
}

const WifiReportChart: React.FC<WifiReportChartProps> = ({
  data,
  sensor,
  // wifiZones,
}) => {
  const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(
    undefined
  );
  const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(
    undefined
  );

  const [reportData, setReportData] = useState<CreateWifiZoneReportDataDto[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // ============ FILTER STATES ============
  const [filterAgeRange, setFilterAgeRange] = useState<string>("");
  const [filterNationality, setFilterNationality] = useState<string>("");
  const [filterGender, setFilterGender] = useState<string>("");

  // ---------------------------------------
  // Step 1: Fetch data if not provided via props
  // ---------------------------------------
  useEffect(() => {
    if (data && data.length > 0) {
      setReportData(data);
    } else {
      const getData = async () => {
        setLoading(true);
        try {
          const [fetchedData, ok] = await getWifiZoneReportData();
          if (!ok) {
            throw new Error("Failed to fetch WiFi zone report data.");
          }
          setReportData(
            sensor
              ? fetchedData.filter((d) => d.sensorId === sensor._id)
              : fetchedData
          );
        } catch (err) {
          console.error(err);
          setError("Error fetching data.");
          toast.error("Error fetching WiFi zone report data.");
        } finally {
          setLoading(false);
        }
      };
      getData();
    }
  }, [data]);

  // ---------------------------------------
  // Step 2: Derive possible filter options
  // (unique values for each column)
  // ---------------------------------------
  const uniqueAgeRanges = useMemo(
    () =>
      Array.from(new Set(reportData.map((d) => d.ageRange.trim())))
        .filter(Boolean)
        .sort(),
    [reportData]
  );

  const uniqueNationalities = useMemo(
    () =>
      Array.from(new Set(reportData.map((d) => d.nationality.trim())))
        .filter(Boolean)
        .sort(),
    [reportData]
  );

  const uniqueGenders = useMemo(
    () =>
      Array.from(new Set(reportData.map((d) => d.gender.trim())))
        .filter(Boolean)
        .sort(),
    [reportData]
  );

  // ---------------------------------------
  // Step 3: Filter the data based on user selections
  // ---------------------------------------
  const filteredData = useMemo(() => {
    return reportData.filter((entry) => {
      if (filterAgeRange && entry.ageRange.trim() !== filterAgeRange) {
        return false;
      }
      if (filterNationality && entry.nationality.trim() !== filterNationality) {
        return false;
      }
      if (filterGender && entry.gender.trim() !== filterGender) {
        return false;
      }
      const entryDate = new Date(entry.timestamp);
      if (filterStartDate && entryDate < startOfDay(filterStartDate))
        return false;
      if (filterEndDate && entryDate > endOfDay(filterEndDate)) return false;

      return true;
    });
  }, [
    reportData,
    filterAgeRange,
    filterNationality,
    filterGender,
    filterStartDate,
    filterEndDate,
  ]);

  // ---------------------------------------
  // Step 4: Process data (counts) for charts
  // ---------------------------------------
  const processData = (dataForCharts: CreateWifiZoneReportDataDto[]) => {
    const genderCounts: { [key: string]: number } = {};
    const nationalityCounts: { [key: string]: number } = {};
    const ageRangeCounts: { [key: string]: number } = {};

    dataForCharts.forEach((entry) => {
      // Gender
      const gender = entry.gender.trim();
      if (gender) {
        genderCounts[gender] = (genderCounts[gender] || 0) + 1;
      }

      // Nationality
      const nationality = entry.nationality.trim();
      if (nationality) {
        nationalityCounts[nationality] =
          (nationalityCounts[nationality] || 0) + 1;
      }

      // Age Range
      const ageRange = entry.ageRange.trim();
      if (ageRange) {
        ageRangeCounts[ageRange] = (ageRangeCounts[ageRange] || 0) + 1;
      }
    });

    return { genderCounts, nationalityCounts, ageRangeCounts };
  };

  const { genderCounts, nationalityCounts, ageRangeCounts } = useMemo(
    () => processData(filteredData),
    [filteredData]
  );

  // ---------------------------------------
  // Step 5: Build ECharts options
  // ---------------------------------------
  const genderOption = {
    title: {
      text: "Distribución por Género",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "40",
    },
    series: [
      {
        name: "Género",
        type: "pie",
        radius: "50%",
        label: {
          formatter: "{b}: {d}%",
        },
        data: Object.entries(genderCounts).map(([name, value]) => ({
          value,
          name,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const nationalityOption = {
    title: {
      text: "Distribución por Nacionalidad",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "40",
    },
    series: [
      {
        name: "Nacionalidad",
        type: "pie",
        radius: "50%",
        label: {
          formatter: "{b}: {d}%",
        },
        data: Object.entries(nationalityCounts).map(([name, value]) => ({
          value,
          name,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const ageRangeOption = {
    title: {
      text: "Distribución por Rango de Edad",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: Object.keys(ageRangeCounts),
      axisLabel: {
        interval: 0,
        rotate: 30,
      },
    },
    yAxis: {
      type: "value",
      name: "Personas",
    },
    grid: {
      left: 40,
    },
    series: [
      {
        name: "Personas",
        type: "bar",
        data: Object.values(ageRangeCounts),
        itemStyle: {
          color: "#5470C6",
        },
      },
    ],
  };

  // ---------------------------------------
  // Step 6: Handle CSV Export (of filtered data)
  // ---------------------------------------
  const handleExportCSV = useCallback(() => {
    if (filteredData.length === 0) return;

    // CSV header
    const headers = ["Fecha", "Rango de Edad", "Género", "Nacionalidad"];

    // Build rows
    const rows = filteredData.map((item) => [
      new Date(item.timestamp).toLocaleString().replace(/,/g, ""), // Remove commas
      item.ageRange,
      item.gender,
      item.nationality,
    ]);

    // Combine into a CSV string
    const csvContent = [
      headers.join(","), // Header row
      ...rows.map((row) => row.join(",")), // Data rows
    ].join("\n");

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Reporte wifi.csv";
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
  }, [filteredData]);

  // ---------------------------------------
  // Step 7: Virtualized Row Component
  // ---------------------------------------
  const TableRow = ({ index, style, data }: ListChildComponentProps) => {
    const item = data[index] as CreateWifiZoneReportDataDto;
    return (
      <div
        style={{
          ...style,
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          background: 'white'
        }}
      >
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              border: "1px solid #ccc",
              padding: "8px",
              verticalAlign: "middle",
            }}
          >
            {new Date(item.timestamp).toLocaleString()}
          </div>
          <div
            style={{
              display: "table-cell",
              border: "1px solid #ccc",
              padding: "8px",
              verticalAlign: "middle",
            }}
          >
            {item.ageRange}
          </div>
          <div
            style={{
              display: "table-cell",
              border: "1px solid #ccc",
              padding: "8px",
              verticalAlign: "middle",
            }}
          >
            {item.gender}
          </div>
          <div
            style={{
              display: "table-cell",
              border: "1px solid #ccc",
              padding: "8px",
              verticalAlign: "middle",
            }}
          >
            {item.nationality}
          </div>
        </div>
      </div>
    );
  };

  // ---------------------------------------
  // Step 8: Rendering
  // ---------------------------------------
  if (loading) {
    return <p>Cargando datos del reporte...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (reportData.length === 0) {
    return <p>No hay datos disponibles para mostrar el reporte.</p>;
  }

  return (
    <>
      {/* --- Filters + Table Controls --- */}
      {sensor && <div className="flex justify-center">{sensor.name}</div>}
      <div className="flex flex-wrap items-center mt-6 gap-4">
        {/* Filter: Age Range */}
        <div>
          <label className="block text-sm font-medium">Rango de Edad:</label>
          <select
            className="border rounded px-2 py-1"
            value={filterAgeRange}
            onChange={(e) => setFilterAgeRange(e.target.value)}
          >
            <option value="">Todos</option>
            {uniqueAgeRanges.map((age) => (
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>

        {/* Filter: Nationality */}
        <div>
          <label className="block text-sm font-medium">Nacionalidad:</label>
          <select
            className="border rounded px-2 py-1"
            value={filterNationality}
            onChange={(e) => setFilterNationality(e.target.value)}
          >
            <option value="">Todos</option>
            {uniqueNationalities.map((nat) => (
              <option key={nat} value={nat}>
                {nat}
              </option>
            ))}
          </select>
        </div>

        {/* Filter: Gender */}
        <div>
          <label className="block text-sm font-medium">Género:</label>
          <select
            className="border rounded px-2 py-1"
            value={filterGender}
            onChange={(e) => setFilterGender(e.target.value)}
          >
            <option value="">Todos</option>
            {uniqueGenders.map((g) => (
              <option key={g} value={g}>
                {g}
              </option>
            ))}
          </select>
        </div>

        {/* NEW: Filter: Fecha de Inicio */}
        <div>
          <label className="block text-sm font-medium">Fecha de Inicio:</label>
          <input
            type="date"
            className="border rounded px-2 py-1"
            onChange={(e) => setFilterStartDate(new Date(e.target.value))}
          />
        </div>

        {/* NEW: Filter: Fecha de Fin */}
        <div>
          <label className="block text-sm font-medium">Fecha de Fin:</label>
          <input
            type="date"
            className="border rounded px-2 py-1"
            onChange={(e) => setFilterEndDate(new Date(e.target.value))}
          />
        </div>
      </div>

      {/* --- Charts Section --- */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
        {/* Gender Distribution Pie Chart */}
        <div className="bg-white shadow rounded p-4">
          <ReactECharts
            option={genderOption}
            style={{ height: "400px", width: "100%" }}
          />
        </div>

        {/* Nationality Distribution Pie Chart */}
        <div className="bg-white shadow rounded p-4">
          <ReactECharts
            option={nationalityOption}
            style={{ height: "400px", width: "100%" }}
          />
        </div>

        {/* Age Range Distribution Bar Chart */}
        <div className="bg-white shadow rounded p-4">
          <ReactECharts
            option={ageRangeOption}
            style={{ height: "400px", width: "100%" }}
          />
        </div>
      </div>

      {/* --- Virtualized Table --- */}

      <div className="flex justify-end mt-4">
        {/* CSV Export Button */}
        <button
          onClick={handleExportCSV}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Exportar CSV
        </button>
      </div>
      <div className="mt-4">
        {/* Table Header (fixed) */}
        <div style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
          <div style={{ display: "table-row", backgroundColor: "#f2f2f2" }}>
            <div
              style={{
                display: "table-cell",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Fecha
            </div>
            <div
              style={{
                display: "table-cell",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Rango de Edad
            </div>
            <div
              style={{
                display: "table-cell",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Género
            </div>
            <div
              style={{
                display: "table-cell",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Nacionalidad
            </div>
          </div>
        </div>

        <List
          className="border border-t-0"
          height={400} // Adjust this as needed
          itemCount={filteredData.length}
          itemSize={50} // row height in pixels
          width={"100%"}
          itemData={filteredData}
        >
          {TableRow}
        </List>
      </div>
    </>
  );
};

export default WifiReportChart;
