import { useState } from "react";

interface DroneCardProps {
  url: string;
  dashboardMode?: boolean;
}

export const DroneCard = ({ url, dashboardMode }: DroneCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!url) return <div></div>;

  return (
    <div
      style={{ zIndex: dashboardMode ? 0 : 200 }}
      className={
        dashboardMode
          ? "w-full h-[600px]"
          : `absolute top-2 left-2 bg-white shadow-lg rounded overflow-hidden transition-all duration-300
        ${
          isExpanded
            ? "w-[calc(90vw)] h-[calc(90vh)] lg:w-[calc(80vw)] lg:h-[calc(80vh)]"
            : "w-[250px] h-[250px] lg:w-[400px] lg:h-[400px]"
        }`
      }
    >
      <div className="relative h-full">
        <iframe src={url} className="w-full h-full" title="Drone Feed" />
        {!dashboardMode && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              padding: "6px 12px",
              background: "rgba(0,0,0,0.6)",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              zIndex: 1000,
            }}
          >
            {isExpanded ? "Colapsar" : "Expandir"}
          </button>
        )}
      </div>
    </div>
  );
};
