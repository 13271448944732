import { ApiResponse } from "@models/api-response";
import { CreateIncidentDto } from "./dto/create-incident.dto";
import { IncidentDto } from "./dto/incident.dto";

export async function createIncident(data: CreateIncidentDto): Promise<ApiResponse<IncidentDto>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/incidents`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    const result = await response.json();
    return [result, true];
  } catch (error) {
    return [null, false];
  }
}
