import { getVariables } from "api/variables/get-variables";
import { DropdownOption } from "models/dropdown-option.model";
import { deleteSensor } from "api/sensor/delete-sensor";
import { getSensors } from "api/sensor/get-sensors";
import CreateSensorForm from "components/forms/sensor/create-sensor-form";
import EditSensorForm from "components/forms/sensor/edit-sensor-form";
import Navbar from "components/navbar";
import { Sensor } from "models/sensor.model";
import { useEffect, useState } from "react";
import { VARIABLES, VARIABLES_LABEL_MAP } from "constants/variables";
import * as XLSX from "xlsx";
import { SENSOR_TYPES } from "constants/sensor-types";
import { CreateSensorDto } from "api/sensor/dto/create-sensor.dto";
import { createSensorMultiple } from "api/sensor/create-sensor";
import { toast } from "react-toastify";
import { useAuth } from "hooks/use-auth";
import MonitoringSensorForm from "components/forms/sensor/monitoring-sensor-form";

export default function MySensors() {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isMonitoringModalOpen, setIsMonitoringModalOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(null);
  const [variables, setVariables] = useState<DropdownOption[]>([]);
  const { isAdmin } = useAuth();

  useEffect(() => {
    fetchSensors();
    fetchVariables();
  }, []);

  async function fetchSensors() {
    const response = await getSensors();

    if (!response.ok) {
      return;
    }

    const sensors = await response.json();
    setSensors(sensors);
  }

  async function fetchVariables() {
    const [variables, ok] = await getVariables();

    if (!ok) {
      return;
    }

    setVariables(variables.map((v) => ({ label: v.title, value: v.name })));
  }

  const handleDelete = async (id: string) => {
    if (!window.confirm("¿Está seguro que desea eliminar el sensor?")) return;

    const response = await deleteSensor(id);

    if (response.ok) {
      setSensors((prevSensors) =>
        prevSensors.filter((sensor) => sensor._id !== id)
      );
    } else {
      console.error("Failed to delete sensor");
    }
  };

  const handleUpdate = async (sensor: Sensor) => {
    setSelectedSensor(sensor);
    setIsUpdateModalOpen(true);
  };

  const handleSensorCreated = () => {
    // Refresh the sensors list after a new sensor is created
    fetchSensors();
    setIsCreateModalOpen(false); // Close the modal after creation
  };

  const handleSensorUpdated = () => {
    // Refresh the sensors list after a sensor is updated
    fetchSensors();
    setIsUpdateModalOpen(false); // Close the modal after update
  };

  const handleMonitoring = async (sensor: Sensor) => {
    setSelectedSensor(sensor);
    setIsMonitoringModalOpen(true);
  };
  /**
   * This function downloads a template file with the sensors to be imported
   * In the first sheet, it shows two rows: the first one is the header, the second one is an example of the data
   * In the second sheet, it shows the list of all the available variables
   */
  function downlodImportSensorsTemplateXLSX() {
    // Create a new XLSX file
    const workbook = XLSX.utils.book_new();

    // Add the first sheet to the workbook
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Nombre", "Descripcion", "Coordenadas", "Tipo", "Variables"],
      [
        "Ejemplo",
        "Descripcion ejemplo",
        "0,0",
        "Punto Smart",
        "Temperatura,Humedad",
      ],
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sensores");

    // Add the second sheet to the workbook
    const variablesSheet = XLSX.utils.aoa_to_sheet([
      ["Nombre variable"],
      ...VARIABLES.map((variable) => [variable.label]),
      [""],
      ["Nota: Las variables deben estar separadas por comas, sin espacios."],
    ]);

    XLSX.utils.book_append_sheet(workbook, variablesSheet, "Variables");

    // Add third sheet to the workbook that will have all the sensor types
    const sensorTypesSheet = XLSX.utils.aoa_to_sheet([
      ["Nombre tipo sensor"],
      ...SENSOR_TYPES.map((type) => [type.name]),
    ]);

    XLSX.utils.book_append_sheet(workbook, sensorTypesSheet, "Tipos de sensor");

    // Download the workbook using the FileSaver library
    XLSX.writeFile(workbook, "Plantilla de importación de sensores.xlsx");
  }

  /**
   * This function imports the sensors from the XLSX file
   */
  async function importSensors(file: File | null) {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target?.result as string;
      const workbook = XLSX.read(data, { type: "binary" });

      // Get the sensors sheet
      const sensorsSheet = workbook.Sheets["Sensores"];

      // Get the data from the sensors sheet
      const sensorsData = XLSX.utils.sheet_to_json(sensorsSheet);

      // Create the sensors
      const sensors: CreateSensorDto[] = (
        sensorsData as {
          Nombre: string;
          Descripcion: string;
          Coordenadas: string;
          Tipo: string;
          Variables: string;
        }[]
      ).map((row) => {
        const type = SENSOR_TYPES.find((type) => type.name === row.Tipo);
        if (!type) {
          throw new Error(`No se encontró el tipo de sensor ${row.Tipo}`);
        }

        const variables = row.Variables
          ? row.Variables.split(",").map((variable) => variable.trim())
          : [];
        const variableNames = variables.map(
          (variable) => VARIABLES_LABEL_MAP.get(variable)?.value ?? null
        );

        const [latitude, longitude] = row.Coordenadas
          ? row.Coordenadas.split(",")
          : ["0", "0"];

        return {
          name: row.Nombre,
          description: row.Descripcion,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          type: type.id,
          variables: variableNames
            .filter((variable) => variable !== null)
            .join(","),
        };
      });

      toast.info("Importando sensores");

      // Import the sensors
      const [_, ok] = await createSensorMultiple(sensors);

      if (!ok) {
        toast.error("Error al importar sensores");
        return;
      }

      toast.success("Sensores importados correctamente");

      // Fetch the sensors again to update the UI
      fetchSensors();
    };

    reader.readAsArrayBuffer(file);
  }

  function hitHiddenFileInput() {
    const fileInput = document.getElementById(
      "import-sensors-file"
    ) as HTMLInputElement;
    fileInput.click();
  }

  function handleExportCSV() {
    if (!sensors || sensors.length === 0) {
      toast.error("No hay sensores para exportar");
      return;
    }

    const sensorsToExport = isAdmin
      ? sensors
      : sensors.map((sensor) => {
          const newSensor = { ...sensor };

          delete (newSensor as any)['_id'];
          delete (newSensor as any)["userId"];

          return newSensor;
        });

    // Convert the sensors array to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(sensorsToExport);

    // Convert the worksheet to CSV format
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    // Create a blob from the CSV data
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a link to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sensores.csv");

    // Append the link, trigger click, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  }

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div
        style={{
          background: " linear-gradient(90deg, #f9d110 35%, #f8ab0f 100%)",
        }}
        className="flex flex-col items-center pt-16 h-[calc(100vh-56px)] w-screen text-black"
      >
        <div className="max-w-6xl w-[80%] min-w-[300px] h- flex flex-col bg-white/70 gap-2 rounded-lg overflow-hidden">
          <div className="mompox-blue text-white flex items-center mb-4 p-4">
            <h1 className="text-2xl font-bold">Mis sensores</h1>
            <div className="flex-auto"></div>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="self-start mompox-light-blue text-white p-2 rounded-lg"
            >
              Nuevo sensor
            </button>
          </div>

          <div className="flex gap-2 items-center px-4">
            {/* Download template file */}
            <button
              className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold"
              onClick={() => downlodImportSensorsTemplateXLSX()}
            >
              Descargar plantilla de importación
            </button>

            {/* Import sensors */}
            <button
              className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold"
              onClick={() => hitHiddenFileInput()}
            >
              Importar sensores
            </button>

            <button
              className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold"
              onClick={handleExportCSV}
            >
              Exportar CSV
            </button>

            {/* Hidden file input */}
            <input
              type="file"
              id="import-sensors-file"
              accept=".xlsx"
              style={{ display: "none" }}
              onChange={(e) =>
                e.target.files && importSensors(e.target.files[0])
              }
            />

            {/* Button to go to the monitoring report page */}

            <a href="/monitoring-report">
              <button className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold">
                Reporte monitoreo
              </button>
            </a>
          </div>

          <div className="p-4 overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="text-lg font-semibold py-2 text-left">
                    Nombre
                  </th>
                  <th className="text-lg font-semibold py-2 text-left hidden lg:table-cell">
                    Variables
                  </th>
                  <th className="text-lg font-semibold py-2 text-left hidden md:table-cell">
                    API Key
                  </th>
                  <th className="text-lg font-semibold py-2 text-left">
                    {/* Actions column */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sensors.map((sensor) => (
                  <SensorItem
                    key={sensor._id}
                    variables={variables}
                    sensor={sensor}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                    isAdmin={isAdmin}
                    onMonitoring={handleMonitoring}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Create Modal */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => setIsCreateModalOpen(false)}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <CreateSensorForm
              variables={variables}
              onSensorCreated={handleSensorCreated}
            />
          </div>
        </div>
      )}
      {/* Edit Modal */}
      {isUpdateModalOpen && selectedSensor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => {
                setIsUpdateModalOpen(false);
                setSelectedSensor(null);
              }}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <EditSensorForm
              sensor={selectedSensor}
              variables={variables}
              onSensorUpdated={handleSensorUpdated}
            />
          </div>
        </div>
      )}

      {/* Monitoring Modal */}
      {isMonitoringModalOpen && selectedSensor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => {
                setIsMonitoringModalOpen(false);
                setSelectedSensor(null);
              }}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <MonitoringSensorForm
              sensor={selectedSensor}
              onSensorUpdated={handleSensorUpdated}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function SensorItem({
  variables,
  sensor,
  onDelete,
  onUpdate,
  isAdmin,
  onMonitoring,
}: {
  variables: DropdownOption[];
  sensor: Sensor;
  onDelete: (id: string) => Promise<void>;
  onUpdate: (sensor: Sensor) => void;
  isAdmin: boolean;
  onMonitoring: (sensor: Sensor) => void;
}) {
  const variablesMap = new Map(variables.map((v) => [v.value, v.label]));

  return (
    <tr className="border-t-2 border-[#c9c9c9]">
      <td className="py-2">{sensor.name}</td>
      <td className="py-2 hidden lg:table-cell">
        <div className="flex flex-wrap gap-2">
          {sensor.variables.split(",").map((v) => (
            <div
              key={v}
              className="bg-gray-200/50 rounded-lg shadow w-fit p-1 text-xs"
            >
              {variablesMap.get(v)}
            </div>
          ))}
        </div>
      </td>
      <td className="py-2 hidden md:table-cell">{sensor.apiKey}</td>
      <td className="py-2">
        <div className="flex gap-2 items-center">
          <button
            className="mompox-blue text-white p-2 rounded-lg"
            onClick={() => onUpdate(sensor)}
          >
            Editar
          </button>
          {isAdmin && (
            <button
              className="mompox-blue text-white p-2 rounded-lg"
              onClick={() => onMonitoring(sensor)}
            >
              Monitoreo
            </button>
          )}
          <button
            className="outline outline-2 outline-red-400 text-red-400 p-1 rounded-lg"
            onClick={() => onDelete(sensor._id)}
          >
            Eliminar
          </button>
        </div>
      </td>
    </tr>
  );
}
