export const INCIDENT_VISIBILITIES = [
  {
    id: "public",
    name: "Pública",
  },
  {
    id: "private",
    name: "Privada",
  },
];
