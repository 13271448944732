import React, { useState, useEffect } from "react";
import { SENSOR_TYPES } from "constants/sensor-types";
import { DropdownOption } from "models/dropdown-option.model";
import { getVariables } from "api/variables/get-variables";
import { endOfDay, format, parse, startOfDay, subDays } from "date-fns";
import { getSensorData } from "api/sensor/get-multi-sensor-data.dto";
import ReactECharts from "echarts-for-react";
import Select from "react-select";
import { Sensor } from "models/sensor.model";
import { toast } from "react-toastify";
import { useAuth } from "hooks/use-auth";
import { DroneCard } from "components/drone-card";
import { CCTVVideoStream } from "./sensor/cctv-sensor-popup";
import WifiReportChart from "components/wifi-report-chart";

// Build a static array of sensor types that support "reportsEnabled"
const REPORT_SENSOR_TYPES: DropdownOption[] = SENSOR_TYPES.filter(
  (type) => type.reportsEnabled
).map((type) => ({
  label: type.name,
  value: type.id,
}));

const ADMIN_SENSOR_TYPES = ["drone", "cctv", "wifi-zone"];
const REPORT_SENSOR_TYPES_ADMIN: DropdownOption[] = SENSOR_TYPES.filter(
  (type) => type.reportsEnabled || ADMIN_SENSOR_TYPES.includes(type.id)
).map((type) => ({
  label: type.name,
  value: type.id,
}));

type ReportFormProps = {
  /** Optional: if provided, we hide the multi-sensor UI
   *  and just generate charts for the single sensor.
   */
  sensor?: Sensor;

  dashboardMode?: boolean;
};

const ReportForm: React.FC<ReportFormProps> = ({ sensor, dashboardMode }) => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const { isAdmin } = useAuth();
  const sensorTypes = isAdmin ? REPORT_SENSOR_TYPES_ADMIN : REPORT_SENSOR_TYPES;

  // Multi-sensor states (used if `sensor` is NOT provided)
  const [sensorType, setSensorType] = useState<string>(sensorTypes[0].value);
  const [selectedSensors, setSelectedSensors] = useState<DropdownOption[]>([]);
  const [availableSensors, setAvailableSensors] = useState<any[]>([]);
  const [availableSensorsMap, setAvailableSensorsMap] = useState<
    Map<string, Sensor>
  >(new Map<string, Sensor>());
  const [filteredSensors, setFilteredSensors] = useState<DropdownOption[]>([]);

  // Variable states
  const [availableVariables, setAvailableVariables] = useState<
    DropdownOption[]
  >([]);
  const [filteredVariables, setFilteredVariables] = useState<DropdownOption[]>(
    []
  );
  const [selectedVariables, setSelectedVariables] = useState<DropdownOption[]>(
    []
  );

  // Chart data
  const [sensorData, setSensorData] = useState<any[] | null>(null);

  /**
   * INITIAL LOAD: fetch all sensors (if needed) + fetch variable definitions
   */
  useEffect(() => {
    if (!sensor) {
      fetchAvailableSensors();
    }
    fetchVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * When sensorType changes, filter the sensors for that type
   * (only if we do NOT have a single `sensor` passed in).
   */
  useEffect(() => {
    if (sensor || !sensorType) return; // skip if single sensor

    setFilteredSensors(
      availableSensors
        .filter((s) => s.type === sensorType)
        .map((s) => ({ label: s.name, value: s._id }))
    );

    // Reset these whenever sensorType changes
    setSensorData(null);
    setSelectedSensors([]);
    setSelectedVariables([]);
  }, [sensorType, availableSensors, sensor]);

  /**
   * Whenever the user picks new sensors, compute the "common variables"
   * across them, so that the user can only pick variables
   * that all selected sensors share.
   */
  useEffect(() => {
    if (sensor) return; // skip if single sensor

    if (!selectedSensors.length) {
      setFilteredVariables([]);
      return;
    }

    // 1. Find each actual sensor in the DB
    const actualSensors = selectedSensors.map((s) =>
      availableSensors.find((as) => as._id === s.value)
    );

    // 2. Get the variables that ALL these sensors share
    const commonVars = actualSensors.reduce((acc, sensorItem) => {
      if (!sensorItem) return acc;
      const sensorVars = sensorItem.variables.split(",");
      // first sensor => set initial
      if (!acc.length) return sensorVars;
      // otherwise, keep only intersection
      return acc.filter((variable: string) => sensorVars.includes(variable));
    }, [] as string[]);

    // 3. Filter the "availableVariables" to keep only those in `commonVars`
    const newFilteredVars = availableVariables.filter((av) =>
      commonVars.includes(av.value)
    );
    setFilteredVariables(newFilteredVars);

    // Reset chart data whenever the selection changes
    setSensorData(null);
  }, [
    selectedSensors,
    availableSensors,
    sensorType,
    sensor,
    availableVariables,
  ]);

  /**
   * For single-sensor mode (if `sensor` is passed):
   * - We skip the multi-sensor UI
   * - We automatically pick all variables from that sensor
   *   (and map them to our availableVariables to get nice labels).
   */
  useEffect(() => {
    if (!sensor || !availableVariables.length) return;

    // Split the sensor.variables string into an array and trim any extra spaces
    const sensorVarNames =
      sensor.variables?.split(",").map((v) => v.trim()) || [];

    // Map sensorVarNames to the corresponding DropdownOption in availableVariables,
    // ensuring that both sides are treated as strings and the original order is preserved.
    const matchedVars = sensorVarNames
      .map((varName) =>
        availableVariables.find((av) => String(av.value).trim() === varName)
      )
      .filter(Boolean) as DropdownOption[];

    setSelectedSensors([{ label: sensor.name, value: sensor._id }]);
    setFilteredVariables(matchedVars);
    setSelectedVariables(matchedVars);
  }, [sensor, availableVariables]);

  /**
   * Both single-sensor and multi-sensor modes:
   * whenever sensors & variables are chosen, fetch data for them
   */
  useEffect(() => {
    if (!selectedSensors.length || !selectedVariables.length) {
      setSensorData(null);
      return;
    }

    fetchSensorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSensors, selectedVariables, startDate, endDate]);

  /**
   * FETCHES
   */
  const fetchAvailableSensors = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/v1/sensors`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log(
          "available sensors: ",
          data.find((d: any) => d.type === "drone")
        );
        setAvailableSensors(data);
        setAvailableSensorsMap(
          data.reduce((acc: Map<string, Sensor>, curr: Sensor) => {
            acc.set(curr._id, curr);

            return acc;
          }, new Map<string, Sensor>())
        );
      } else {
        console.error("Error al obtener los sensores");
      }
    } catch (error) {
      console.error("Error al obtener los sensores:", error);
    }
  };

  const fetchVariables = async () => {
    const [variables, ok] = await getVariables();
    if (!ok) {
      return;
    }
    // Transform to { label, value }
    const newVars = variables.map((v: any) => ({
      label: `${v.title} (${v.unit})`,
      value: v.name,
    }));
    setAvailableVariables(newVars);
  };

  const fetchSensorData = async () => {
    const startIso = startOfDay(startDate).toISOString();
    const endIso = endOfDay(endDate).toISOString();
    try {
      const [data, ok] = await getSensorData(
        selectedSensors.map((s) => s.value).join(","),
        startIso,
        endIso
      );

      if (!ok) {
        toast.error("Error al obtener los datos");
        return;
      }

      setSensorData(data);
    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  };

  /**
   * RENDER
   */
  return (
    <div className="w-full text-black flex flex-col gap-4">
      {/* Date Pickers */}
      <div>
        <label className="block mb-1">Fecha inicio</label>
        <input
          type="date"
          value={format(startDate, "yyyy-MM-dd")}
          onChange={(e) => {
            const parsedDate = parse(e.target.value, "yyyy-MM-dd", new Date());
            setStartDate(parsedDate);
          }}
          className="border p-2 w-full rounded"
        />
      </div>
      <div>
        <label className="block mb-1">Fecha fin</label>
        <input
          type="date"
          value={format(endDate, "yyyy-MM-dd")}
          onChange={(e) => {
            const parsedDate = parse(e.target.value, "yyyy-MM-dd", new Date());
            setEndDate(parsedDate);
          }}
          className="border p-2 w-full rounded"
        />
      </div>

      {/* If NO single sensor is passed, show sensor-type and sensor selections */}
      {!sensor && sensorType !== "drone" && sensorType !== "wifi-zone" && (
        <>
          <div>
            <label className="block mb-1">Tipo de sensor</label>
            <Select
              options={sensorTypes}
              value={sensorTypes.find((option) => option.value === sensorType)}
              onChange={(selected) => setSensorType(selected!.value)}
              placeholder="Tipo de sensor"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            <label className="block mb-1">Sensores</label>
            <Select
              options={filteredSensors}
              isMulti
              value={selectedSensors}
              onChange={(selected) =>
                setSelectedSensors(selected as DropdownOption[])
              }
              placeholder="Sensores"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            {sensorType !== "cctv" && (
              <>
                <label className="block mb-1">Variables</label>
                <Select
                  options={filteredVariables}
                  isMulti
                  value={selectedVariables}
                  onChange={(selected) =>
                    setSelectedVariables(selected as DropdownOption[])
                  }
                  placeholder="Variables"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </>
            )}
          </div>
        </>
      )}

      {!sensor && sensorType === "drone" && (
        <>
          <div>
            <label className="block mb-1">Tipo de sensor</label>
            <Select
              options={sensorTypes}
              value={sensorTypes.find((option) => option.value === sensorType)}
              onChange={(selected) => setSensorType(selected!.value)}
              placeholder="Tipo de sensor"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </>
      )}

      {!sensor && sensorType === "wifi-zone" && (
        <>
          <div>
            <label className="block mb-1">Tipo de sensor</label>
            <Select
              options={sensorTypes}
              value={sensorTypes.find((option) => option.value === sensorType)}
              onChange={(selected) => setSensorType(selected!.value)}
              placeholder="Tipo de sensor"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            <label className="block mb-1">Zona WIFI</label>
            <Select
              options={filteredSensors}
              value={selectedSensors}
              onChange={(selected) =>
                setSelectedSensors([selected] as DropdownOption[])
              }
              placeholder="Zona WIFI"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </>
      )}

      {/* If single sensor is passed, 
          we skip sensor-type + sensor selection
          and automatically pick all sensor variables.
       */}

      {/* Render Charts */}
      {sensorType !== "drone" &&
        selectedSensors.length > 0 &&
        selectedVariables.length > 0 &&
        (sensorData ? (
          <div className="mt-4">
            {selectedVariables.map((variable) => {
              // Build the series array. Each sensor is a separate line
              const seriesData = selectedSensors.map((sensorOpt) => {
                // Filter out the sensorData for this sensor
                const dataPoints = sensorData.filter(
                  (d) => d.sensorId === sensorOpt.value
                );
                // Convert data points to [time, value]
                let lineData = dataPoints
                  .map((dp) => {
                    const numericValue = dp[variable.value] || 0;
                    const dateString = dp.createdAt;
                    return [dateString, numericValue];
                  })
                  .sort(
                    ([dateStr1], [dateStr2]) =>
                      +new Date(dateStr1) - +new Date(dateStr2)
                  );

                if (variable.value !== "precipitation") {
                  lineData = lineData.filter((dp) => dp[1] > 0);
                }

                return {
                  name: sensor ? variable.label : sensorOpt.label,
                  type: "line",
                  data: lineData,
                  showSymbol: false,
                };
              });

              console.log(
                sensor,
                selectedSensors.map((s) => s.label)
              );
              const option = {
                title: { text: variable.label },
                tooltip: { trigger: "axis" },
                legend: {
                  data: selectedSensors.map((s) => s.label),
                },
                xAxis: {
                  type: "time",
                },
                yAxis: {
                  type: "value",
                },
                series: seriesData,
                dataZoom: [
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                  {
                    start: 90,
                    end: 100,
                    // tslint:disable-next-line:max-line-length
                    handleIcon:
                      "M10.7,11.9v-1.3H9.3v1.3c-" +
                      "4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9." +
                      "1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4" +
                      ".4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11." +
                      "9z M13.3,24.4H6.7V23h6.6V24.4z M13.3," +
                      "19.6H6.7v-1.4h6.6V19.6z",
                    handleStyle: {
                      color: "#fff",
                      shadowBlur: 3,
                      shadowColor: "rgba(0, 0, 0, 0.6)",
                      shadowOffsetX: 2,
                      shadowOffsetY: 2,
                    },
                  },
                ],
              };

              return (
                <div key={variable.value} style={{ marginBottom: "2rem" }}>
                  <ReactECharts
                    option={option}
                    style={{ height: "400px", width: "100%" }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <p className="mt-4">No hay datos disponibles</p>
        ))}

      {sensorType === "drone" && (
        <DroneCard
          url={availableSensors.find((s) => s.type === "drone")?.droneIframeUrl}
          dashboardMode
        ></DroneCard>
      )}

      {sensorType === "cctv" && (
        <div className="w-full flex flex-col">
          {selectedSensors.map((sensor) => (
            <div>
              <div>{sensor.label}</div>
              <CCTVVideoStream
                sensor={availableSensorsMap.get(sensor.value)!}
              />
            </div>
          ))}
        </div>
      )}

      {sensorType === "wifi-zone" && selectedSensors.length > 0 && (
        <WifiReportChart
          sensor={availableSensorsMap.get(selectedSensors[0].value)!}
        />
      )}

      {sensorType === "wifi-zone" && selectedSensors.length === 0 && (
        <WifiReportChart />
      )}
    </div>
  );
};

export default ReportForm;
