import Navbar from "components/navbar";
import { FC, useEffect, useState } from "react";
import CreateIncidentForm from "components/forms/incident/create-incident-form";
import UpdateIncidentForm from "components/forms/incident/update-incident-form";
import { getAllIncidents } from "api/incident/get-incidents";
import { IncidentDto } from "api/incident/dto/incident.dto";
import { deleteIncident } from "api/incident/delete-incident";

export const IncidentsList: FC = () => {
  const [incidents, setIncidents] = useState<IncidentDto[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState<IncidentDto | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchIncidents();
  }, []);

  async function fetchIncidents() {
    const [incidents, ok] = await getAllIncidents();

    if (ok) {
      setIncidents(incidents);
    }

    setError("No se pudieron obtener los rangos ICA, intente nuevamente.");
  }

  const handleDelete = async (id: string) => {
    const [, ok] = await deleteIncident(id);

    if (ok) {
      setIncidents([]);
      fetchIncidents();
    }

    setError("No se pudo eliminar el rango ICA, intente nuevamente.");
  };

  const handleUpdate = async (incident: IncidentDto) => {
    setSelectedIncident(incident);
    setIsUpdateModalOpen(true);
  };

  const handleIncidentCreated = () => {
    fetchIncidents();
    setIsCreateModalOpen(false);
  };

  const handleIncidentUpdated = () => {
    fetchIncidents();
    setIsUpdateModalOpen(false);
  };

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div className="flex flex-col bg-[#fbb930] items-center pt-4 w-screen text-black">
        <div className="max-w-6xl w-[80%] min-w-[300px] flex flex-col bg-white/70 rounded-lg overflow-hidden gap-2">
          <div className="flex items-center mb-4 text-white mompox-blue  p-4 ">
            <h1 className="text-2xl font-bold">Incidentes</h1>
            <div className="flex-auto"></div>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="self-start mompox-light-blue text-white p-2 rounded-lg"
            >
              Nuevo Registro
            </button>
          </div>

          <div className="p-4 overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="text-lg font-semibold py-2 text-left">
                    Título
                  </th>
                  <th className="text-lg font-semibold py-2 text-left hidden lg:table-cell w-[120px]">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {incidents.map((incident) => (
                  <IncidentItem
                    key={incident._id}
                    incident={incident}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Create Modal */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <div className="w-full max-w-md p-4 bg-white/70 rounded-lg">
              <button
                onClick={() => setIsCreateModalOpen(false)}
                className="text-red-500 font-bold float-right mb-4"
                data-modal-hide="defaultModal"
              >
                x
              </button>
              <CreateIncidentForm onIncidentCreated={handleIncidentCreated} />
            </div>
          </div>
        </div>
      )}

      {/* Edit Modal */}
      {isUpdateModalOpen && selectedIncident && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => {
                setIsUpdateModalOpen(false);
                setSelectedIncident(null);
              }}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <UpdateIncidentForm
              incident={selectedIncident}
              onIncidentUpdated={handleIncidentUpdated}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface IncidentItemProps {
  incident: IncidentDto;
  onDelete: (id: string) => Promise<void>;
  onUpdate: (incident: IncidentDto) => void;
}

const IncidentItem: FC<IncidentItemProps> = ({ incident, onDelete, onUpdate }) => {
  return (
    <tr className="border-t-2 border-[#c9c9c9]">
      <td className="py-2">{incident.title}</td>
      <td className="py-2">
        <div className="flex gap-2">
          <button
            className="mompox-blue text-white p-2 rounded-lg"
            onClick={() => onUpdate(incident)}
          >
            Editar
          </button>
          <button
            className="outline outline-2 outline-red-400 text-red-400 p-1 rounded-lg"
            onClick={() => onDelete(incident._id)}
          >
            Eliminar
          </button>
        </div>
      </td>
    </tr>
  );
};
