import { ApiResponse } from "models/api-response";

export async function getSensorCountsByType(): Promise<
  ApiResponse<{ [sensorType: string]: number }>
> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/sensors/stats/count-by-type`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.ok) {
      return [await response.json(), true];
    }
  } catch (e) {
    return [null, false];
  }

  return [null, false];
}
