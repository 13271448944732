import Select from "react-select";
import { FormEvent, useState } from "react";
import { DropdownOption } from "models/dropdown-option.model";
import { createSensor } from "api/sensor/create-sensor";
import { SENSOR_TYPES } from "constants/sensor-types";
import { Instructions } from "./sensor-instructions";
import UploadFile from "components/upload-file";
// Assuming you create a new component for multiple file uploads
import { useAuth } from "hooks/use-auth";
import UploadMultipleFiles from "components/upload-multiple-files";

export default function CreateSensorForm({
  onSensorCreated,
  variables,
}: {
  onSensorCreated: () => void;
  variables: DropdownOption[];
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedVariables, setSelectedVariables] = useState<DropdownOption[]>(
    []
  );
  const [sensorType, setSensorType] = useState("weather-station");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [droneIframeUrl, setDroneIframeUrl] = useState("");
  const [rtspUrl, setRtspUrl] = useState("");
  const [rtspUrl2, setRtspUrl2] = useState("");
  const [rtspUrl3, setRtspUrl3] = useState("");
  const [rtspUrl4, setRtspUrl4] = useState("");
  const [isActiveDataFetchingEnabled, setIsActiveDataFetchingEnabled] =
    useState(false);
  const [dataFetchingProfile, setDataFetchingProfile] = useState("");
  const [activeDataFetchingConfig, setActiveDataFetchingConfig] = useState({
    kunakSerialNumber: "",
  });

  const { isAdmin } = useAuth();

  const availableVariables: DropdownOption[] = variables;
  const sensorTypes: DropdownOption[] = SENSOR_TYPES.filter(
    (type) => isAdmin || type.adminOnly === false
  ).map((type) => ({
    label: type.name,
    value: type.id,
  }));
  const activeDataFetchingProfiles: DropdownOption[] = [
    {
      label: "Kunak",
      value: "kunak",
    },
  ];

  // For sensors that are not "smart-mural", use a single image upload.
  const [imageUrl, setImageUrl] = useState("");
  // New state for multiple images (carousel URLs)
  const [carouselURLs, setCarouselURLs] = useState<string[]>([]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await createSensor({
      name,
      description,
      variables: selectedVariables.map((v) => v.value).join(","),
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      type: sensorType,
      // Use imageUrl for non-smart-mural sensors and carouselURLs for smart-mural
      imageUrl: sensorType === "smart-mural" ? undefined : imageUrl,
      carouselURLs: sensorType === "smart-mural" ? carouselURLs : undefined,
      rtspUrl,
      rtspUrl2,
      rtspUrl3,
      rtspUrl4,
      isActiveDataFetchingEnabled,
      dataFetchingProfile,
      activeDataFetchingConfig,
      droneIframeUrl,
    });

    if (response.ok) {
      onSensorCreated();
      setName("");
      setSelectedVariables([]);
      setLatitude("");
      setLongitude("");
      setError(null);
      setImageUrl("");
      setCarouselURLs([]);
    } else {
      setError("Hubo un error al crear el sensor, intente de nuevo.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-black">
      <h2 className="text-2xl text-mompox-blue font-bold">Nuevo sensor</h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        required
        className="p-2 rounded-lg border"
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        className="p-2 rounded-lg border"
      />
      <Select
        options={availableVariables}
        isMulti
        value={selectedVariables}
        onChange={(selected) =>
          setSelectedVariables(selected as DropdownOption[])
        }
        placeholder="Variables"
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <Select
        options={sensorTypes}
        value={sensorTypes.find((option) => option.value === sensorType)}
        onChange={(selected) => setSensorType(selected!.value)}
        placeholder="Tipo de sensor"
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <input
        type="number"
        value={latitude}
        onChange={(e) => setLatitude(e.target.value)}
        placeholder="Latitude"
        required
        className="p-2 rounded-lg border"
      />
      <input
        type="number"
        value={longitude}
        onChange={(e) => setLongitude(e.target.value)}
        placeholder="Longitude"
        required
        className="p-2 rounded-lg border"
      />

      {isAdmin && sensorType === "drone" && (
        <div className="flex flex-col gap-2">
          <div className="text-lg font-semibold">Configuración de dron</div>
          <div className="flex flex-col gap-1">
            <div>URL iframe</div>
            <input
              value={droneIframeUrl}
              onChange={(e) => setDroneIframeUrl(e.target.value)}
              placeholder="URL iframe"
              className="p-2 rounded-lg border"
            />
          </div>
        </div>
      )}

      {isAdmin && sensorType === "smart-point" && (
        <div className="flex flex-col gap-2">
          <div className="text-lg font-semibold">Configuración de cámara</div>
          <div className="flex flex-col gap-1">
            <div>URL RTSP 1</div>
            <input
              value={rtspUrl}
              onChange={(e) => setRtspUrl(e.target.value)}
              placeholder="URL RTSP 1"
              className="p-2 rounded-lg border"
            />
          </div>
        </div>
      )}

      {isAdmin && sensorType === "cctv" && (
        <div className="flex flex-col gap-2">
          <div className="text-lg font-semibold">Configuración de cámara</div>
          <div className="flex flex-col gap-1">
            <div>URL RTSP 1</div>
            <input
              value={rtspUrl}
              onChange={(e) => setRtspUrl(e.target.value)}
              placeholder="URL RTSP 1"
              className="p-2 rounded-lg border"
            />
          </div>
          <div className="flex flex-col gap-1">
            <div>URL RTSP 2</div>
            <input
              value={rtspUrl2}
              onChange={(e) => setRtspUrl2(e.target.value)}
              placeholder="URL RTSP 2"
              className="p-2 rounded-lg border"
            />
          </div>
          <div className="flex flex-col gap-1">
            <div>URL RTSP 3</div>
            <input
              value={rtspUrl3}
              onChange={(e) => setRtspUrl3(e.target.value)}
              placeholder="URL RTSP 3"
              className="p-2 rounded-lg border"
            />
          </div>
          <div className="flex flex-col gap-1">
            <div>URL RTSP 4</div>
            <input
              value={rtspUrl4}
              onChange={(e) => setRtspUrl4(e.target.value)}
              placeholder="URL RTSP 4"
              className="p-2 rounded-lg border"
            />
          </div>
        </div>
      )}

      {isAdmin && (
        <div className="flex flex-col gap-2">
          <div>
            <label className="block mb-1">
              <input
                type="checkbox"
                checked={isActiveDataFetchingEnabled}
                onChange={(e) =>
                  setIsActiveDataFetchingEnabled(e.target.checked)
                }
                className="mr-2"
              />
              Activar monitoreo activo
            </label>
          </div>
          {isActiveDataFetchingEnabled && (
            <Select
              options={activeDataFetchingProfiles}
              value={activeDataFetchingProfiles.find(
                (option) => option.value === dataFetchingProfile
              )}
              onChange={(selected) => setDataFetchingProfile(selected!.value)}
              placeholder="Perfil de datos"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          )}
          {isActiveDataFetchingEnabled && dataFetchingProfile === "kunak" && (
            <div className="flex flex-col gap-2">
              <div className="text-lg font-semibold">
                Configuración de Kunak
              </div>
              <div className="flex flex-col gap-1">
                <div>Serial</div>
                <input
                  value={activeDataFetchingConfig?.kunakSerialNumber}
                  onChange={(e) =>
                    setActiveDataFetchingConfig({
                      ...activeDataFetchingConfig,
                      kunakSerialNumber: e.target.value,
                    })
                  }
                  className="p-2 rounded-lg border"
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Conditional image upload based on sensor type */}
      {sensorType === "smart-mural" ? (
        <div className="flex flex-col gap-2">
          <UploadMultipleFiles
            imageUrls={carouselURLs}
            onFilesUploaded={(urls) => setCarouselURLs(urls)}
          />
        </div>
      ) : (
        <UploadFile
          imageUrl={imageUrl}
          onFileUploaded={(newFileName) => setImageUrl(newFileName)}
        />
      )}

      <button type="submit" className="mompox-blue text-white p-2 rounded-lg">
        Nuevo sensor
      </button>
      {error && <p className="text-red-500">{error}</p>}
      <Instructions />
    </form>
  );
}
